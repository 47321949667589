module.exports = {
    RANK_OPTIONS: [
        { value: 1, text: "Iron" },
        { value: 2, text: "Bronze" },
        { value: 3, text: "Silver" },
        { value: 4, text: "Gold" },
        { value: 5, text: "Platinum" },
        { value: 6, text: "Diamond" },
    ],
    RANKS: {
        IRON: 1,
        BRONZE: 2,
        SILVER: 3,
        GOLD: 4,
        PLATINUM: 5,
        DIAMOND: 6,
    },
    LANGUAGE_OPTIONS: [
        { value: 'vi', text: "Việt Nam" },
        { value: 'th', text: "Thailand" },
        { value: 'id', text: "Indonesia" },
        { value: 'en', text: "English" },
    ],
}