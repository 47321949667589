<template>
    <div>
        <page-breadcrumb title="Exams" class="mb-2" />
        <b-row class="mb-2">
            <b-col md="5">
                <label for="active-date-query">Active date</label>
                <datetime-picker :config="{ enableTime: true, altFormat: 'd/m/Y, H:i:S', dateFormat: 'Z', mode: 'range'}"
                    v-model="query.active_date" id="active-date-query"
                />
            </b-col>
            <b-col md="5">
                <label for="expired-date-query">Expired date</label>
                <datetime-picker :config="{ enableTime: true, altFormat: 'd/m/Y, H:i:S', dateFormat: 'Z', mode: 'range'}"
                    v-model="query.expired_date" id="expired-date-query"
                />
            </b-col>
            <b-col class="d-flex align-items-end" md="2">
                <b-button variant="success" @click="search">Search</b-button>
            </b-col>
        </b-row>
        <form-generator :model="query" :schema="query_schema" />
        <data-table-ssr id="group_list" ref="group_list"
            :columns="fields" :get-list-fn="getList"
        />
    </div>
</template>
<script>
import { LANGUAGE_OPTIONS } from '@/modules/goga_rank/virtual_user/constants';
import service from '../service'
const fields = [
  { label: 'Tên', field: 'name' },
  { label: 'SĐT', field: 'phone' },
  { label: 'Email', field: 'email' },
  { label: 'Product Key', field: 'product_key' },
  { label: 'Actived date', field: 'active_date' },
  { label: 'Expire date', field: 'expired_date' },
  { label: 'Total Learn time', field: 'learn_time' },
  { label: 'Arena', field: 'arena' },
  { label: 'Breaking', field: 'breaking' },
  { label: 'Pronun', field: 'pronun' },
  { label: 'Language', field: 'language' },
];
const query_schema = [
  {
    cols: 2,
    fields: [{ field: 'is_unlimited', label: 'Lifetime', input_type: 'switch' }]
  },
  {
    cols: 2,
    fields: [{ field: 'learn_time', label: 'Learntime', input_type: 'number', min: 0 }]
  },
  {
    cols: 2,
    fields: [{ field: 'language', label: 'Language', input_type: 'select', options: LANGUAGE_OPTIONS }]
  },
]
export default {
    data(){
        return {
            fields,
            current_page: 1,
            total: 0,
            items_perpage: 30,
            query: {
                is_unlimited: false,
                active_date: new Date(new Date().getFullYear(), new Date().getMonth(), 1) + " to " + new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)

            },
            LANGUAGE_OPTIONS: [
                {text: "vi", value: "vi"},
                {text: "th", value: "th"},
                {text: "id", value: "id"},
                {text: "en", value: "en"},
            ],
            query_schema
        }
    },
    watch: {
        // current_page() {
        //     this.getList();
        // },
        // query: {
        //     handler() {
        //         this.getList();
        //     },
        //     deep: true,
        // },
    },
    methods: {
        async getList({ limit = 9, page = 1 }) {
            let response_data = await service.getList({
                query: JSON.stringify({ ...this.query }),
                page, limit
            });
            let list = [], total = 0;
            if (response_data) {
                list = response_data.list;
                total = response_data.total;
                this.total = total;
            }
            return { list, total };
        },
        search(){
            this.$refs.group_list.changePage(1);
            this.$refs.group_list.getList()
        }
    }
}
</script>